<template>
    <div class="cs-logo-reel cs-block" :class="`${styleClass}${hasBg}`">
        <div class="cs-block-base">
            <div class="container">
                <div class="header-content">
                    <h2 class="cs-title" v-if="data.title">
                        <ColorSplit :data="data.title" />
                    </h2>
                    <h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
                    <span v-if="data.text" class="cs-text" v-html="data.text"></span>
                </div>
                <Slider class="slider" :data="data.list" v-slot="item">
                    <CSButton :data="item.slide.link">
                        <img
                            :src="item.slide.icon_image"
                            class="slider__item-image"
                        />
                        <h6 v-if="item.slide.title">
                            {{ item.slide.title }}
                        </h6>
                        <span v-if ="item.slide.text" v-html="item.slide.text"/>
                    </CSButton>
                </Slider>
            </div>
        </div>
    </div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
    name: 'IconSlider',
    components: { 
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
        Slider: () => import("../blocks_components/Slider.vue"),
        CSButton: () => import("../components/CSButton.vue"),
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        ...computed('LogoReel'),
        hasBg() {
            if (this.data.style == 'color') return ' has-background';

            return '';
        }
    }
}
</script>

<style scoped lang="scss">
@import '../styles/main.scss';
.cs-logo-reel {
    position: relative;

    .header-content {
        text-align: center;
        margin-bottom: $header_margin;
    
        &-richtext {
            ::v-deep {
                * {
                    text-align: center;
                }
    
                span {
                    @include text;
                }
            }
        }
    }

    .slider {
        ::v-deep .cs-button  {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: inherit;
        }
        .slider__item-image {
            height: 80px;
            width: 100%;
            object-fit: cover;
            margin-bottom: $theme_items_margin;
        }

        h6, span {
            text-align: center;
        }

        ::v-deep {
            .slider__arrow {
                font-size: 1.5rem !important
            }
        }
    }

    &.cs-style- {
        &color {
            @include cs-block-color;
            .cs-sub-title{
                color:$sub_title_color;
            }
            *, ::v-deep *{
                color:white;
            }
        }

        &light {
            @include cs-block-light;
            .cs-sub-title{
                color: $sub_title_color_light;
            }
        }

        &dark {
            @include cs-block-dark;
            .cs-sub-title{
                color: $sub_title_color_dark;
            }
        }
    }
}
</style>

